@import "../base/module/nav";
@import './app.css';
@import '../variable/app.scss';
@import "../bootstrap/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons";

.i-frame{
  height: 500px;

  @media screen and (max-width: 768px) {

    height: 200px;
  }
}

.btn-white {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #ffffff !important;
  --bs-btn-border-color: #ffffff;
  --bs-btn-border-radius: 0.5rem;
  --bs-btn-hover-color: #3c057e !important;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: #ffffff;
  --bs-btn-active-color: #3c057e;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
}

.bg-payment {
  background-image: url('/public/static/pages/success-payment/bg-left.png'), url('/public/static/pages/success-payment/bg-right.png'), url('/public/static/pages/success-payment/bg-bottom.png');
  background-position: top left, center right, left bottom;
  background-repeat: no-repeat, no-repeat, no-repeat;
  @media screen and (max-width: 900px) {
    background-image: url('/public/static/pages/success-payment/bg-m.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.article-read{
  width: 100%;
  @media screen and (max-width: 1024px){
    width: auto;
  }
}
.plan-cards:hover{
background-color: #FBFBFB;
  cursor: pointer;
}
.bg-badge{
  background-image: url('/public/image/front/cases/badge-code/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 450px;
  background-position: center;
  @media screen and (max-width: 432px)  {
    background-image: url('/public/image/front/cases/badge-code/bg-m.png');
    min-height: 800px;
  }
}
.event-card-catalog{
  height:315px;
  @media screen and (min-width: 992px) {
    height: 719px;
  }
  @media screen and (min-width: 1400px) {
    height: 770px;
  }
}
.event-card{
  height:365px;
}
.price {
  margin-bottom: 4px;
}
@media screen and (min-width: 992px) {
  .price {
    margin-bottom: 0;
  }
}
.tickets.bg-success{
  background-color: #16B054!important;
}
.tickets .text-success{
  color: #16B054!important;
}
.google-auth-page{
  background: linear-gradient(298deg, #FFF -16.06%, #FBE4FF 53.73%, #CDC8FF 96.08%);
  padding: 60px 0;
  & .bg-white{
    padding: 150px 16px;
    @media screen and (max-width: 546px) {
      padding: 75px 16px;
    }
  }
  .form-floating:has(.text-danger) .form-control {
    border-color: #f44336!important;
  }
}
@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
}

.me-page-bg {

    background-image: url("/public/image/me-page-bg.svg");

  background-color: #6463c2;
}


.main-title {
  font-size: 26px;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  line-height: 110%;
  @media screen and (max-width: 432px) {
    font-size: 26px;
  }
}