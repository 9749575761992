.container {
    color: #3e4857;
}

.top-bg {
    background-image: linear-gradient(90deg, #1D0C5E 0%, #4D074A 60.89%, #3A047D 99.9%);
}
ul.instructions li::marker{
    color: #7A6FF7;
}
.hero {
    background-image: linear-gradient(
            90deg,
            #040013 0%,
            #42063f 54.31%,
            #3a047d 99.9%
    );
}

.bg-gray-e5 {
    background-color: #e5e5e5;
}

.bg-footer {
    background-color: #eeeeee;
}


.bg-form {
    background: #ffffff;
    border: 1px solid #f5f5f5;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
}

.bg-first-second-checkbox {
    background: #9b27af;
    color: #ffffff;
    border-radius: 2px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg-third-checkbox {
    border: 1px solid #9b27af;
    border-radius: 2px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9b27af;
    font-weight: 400;
}

.block-iframe {
    height: 470px;
}

.main-block {
    background: linear-gradient(297.73deg, #FFFFFF -16.06%, #FBE4FF 53.73%, #CDC8FF 96.08%), #D5D5D5;
    padding-bottom: 50px;
    padding-top: 60px;
}

/*.card-item {*/
/*    !*min-height: 520px;*!*/
/*}*/

.nav-tabs .nav-item .nav-link.active {
    background-color: #F8F8F8;
    transition: background-color 0.3s 0.2s;
}

/*.breadcrumb-color {*/
/*    color: #3E4957;*/
/*}*/

.nav-pills .nav-link {
    color: #3E4957;
}

.nav-pills .nav-link:hover {
    color: #3E4957;
}

.nav.nav-pills .nav-link.active {
    background-color: #FFFFFF;
    /*border-radius: 3px;*/
    color: #3E4957;
    /*font-weight: 700;*/
}


.stylish-input-group .input-group-addon {
    background: white !important;
    max-width: 287px;
}

.stylish-input-group .form-control {
    border-right: 0;
    box-shadow: 0 0 0;
    border-color: #ccc;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
}

.stylish-input-group button {
    border: 0;
    background: transparent;
}

input[type=search]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.pagination .page-link {
    color: #3E4957;
    border: 0;
}

.page-link:active {
    background: #9B27AF;
    border: solid #9B27AF;
    color: #fff;
}

.card-body .card-footer {
    background-color: inherit;
    border-top: 0;
}


.watch-button{
    padding: 14px!important;
}

.bg-affiliate {
    background-image: url('/public/static/pages/success-payment/img-affiliate.png'), url('/public/static/pages/success-payment/ornament-affiliate.png'), linear-gradient(333deg, #FFF 3.81%, #FBE4FF 63.67%, #CDC8FF 100%);
    background-position: center right, left top;
    background-repeat: no-repeat, no-repeat;
}

.bg-museum {
    background-image: url('/public/static/pages/success-payment/ornament-museum.png'), url('/public/static/pages/success-payment/img-museum.png'), radial-gradient(370.51% 342.32% at -45.08% -43.92%, #EB301D 0%, #0E0D17 34.38%, #0E0D17 52.60%, #EB5647 80.21%);
    background-position: center, bottom right;
    background-repeat: no-repeat, no-repeat;
    background-color: #0E0D17;

}

.bg-ticket {
    background-image: url('/public/static/pages/success-payment/img-ticket.png'), url('/public/static/pages/success-payment/ornament-ticket.png'), radial-gradient(228.77% 105.08% at 81.01% 33.08%, #FFF 3.81%, #E4EDFF 63.67%, #D7FDE6 100%), linear-gradient(333deg, #FFF 3.81%, #FBE4FF 63.67%, #CDC8FF 100%);
    background-position: bottom right, left bottom;
    background-repeat: no-repeat, no-repeat;

}

.bg-art {
    background-image: url('/public/static/pages/success-payment/ornament-art.png'), url('/public/static/pages/success-payment/img-art.png');
    background-position: center, bottom right;
    background-repeat: no-repeat, no-repeat;
    background-color: #EBE9FE;

}

.bg-review {
    background: linear-gradient(333deg, #FFF 3.81%, #FBE4FF 63.67%, #CDC8FF 100%), #D4D4D4;
}

.review-stars {
    color: #FED201
}

.fs-7 {
    font-size: 14px !important;
}
.bg-different{
    background-image: url('/public/static/pages/different-qr-code-types/main-img.webp');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
}
.bg-badge{
    background-image: url('/public/image/front/cases/badge-code/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 812px;
        background-image: url('/public/image/front/cases/badge-code/bg-m.png');
    }
}
.bg-book{
    background-image: url('/public/image/front/cases/book-qr-codes/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 812px;
        background-image: url('/public/image/front/cases/book-qr-codes/bg-m.png');
    }
}
.bg-clothing{
    background-image: url('/public/image/front/cases/clothing-qr-codes/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 812px;
        background-image: url('/public/image/front/cases/clothing-qr-codes/bg-m.png');
    }
}
.bg-billboard{
    background-image: url('/public/image/front/cases/billboard-qr-codes/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 812px;
        background-image: url('/public/image/front/cases/billboard-qr-codes/bg-m.png');
    }
}
.bg-ordering{
    background-image: url('/public/image/front/cases/menu-ordering-qr-codes/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 812px;
        background-image: url('/public/image/front/cases/menu-ordering-qr-codes/bg-m.png');
    }
}
.bg-qr-websites{
    background-image: url('/public/image/front/cases/qr-website/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 812px;
        background-image: url('/public/image/front/cases/qr-website/bg-m.png');
    }
}.bg-qr-vaccination{
    background-image: url('/public/image/front/cases/qr-vaccination/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 812px;
        background-image: url('/public/image/front/cases/qr-vaccination/bg-m.png');
    }
}
.bg-qr-magazine{
    background-image: url('/public/image/front/cases/qr-magazine/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 812px;
        background-image: url('/public/image/front/cases/qr-magazine/bg-m.png');
    }
}
.bg-qr-banner{
    background-image: url('/public/image/front/cases/qr-banner/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 812px;
        background-image: url('/public/image/front/cases/qr-banner/bg-m.png');
    }
}
.bg-qr-car{
    background-image: url('/public/image/front/cases/qr-car/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 812px;
        background-image: url('/public/image/front/cases/qr-car/bg-m.png');
    }
}
.bg-qr-brochure{
     background-image: url('/public/image/front/cases/qr-code-brochure/bg.png');
     background-repeat: no-repeat;
     background-size: cover;
     min-height: 450px;
     @media screen and (max-width: 431px){
         min-height: 812px;
         background-image: url('/public/image/front/cases/qr-code-brochure/bg-m.png');
     }
 }
.bg-qr-flyer{
    background-image: url('/public/image/front/cases/qr-code-on-flyer/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 1012px;
        background-image: url('/public/image/front/cases/qr-code-on-flyer/bg-m.png');
    }
}
.bg-qr-food{
    background-image: url('/public/image/front/cases/qr-code-for-food/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 1012px;
        background-image: url('/public/image/front/cases/qr-code-for-food/bg-m.png');
    }
}
.bg-qr-product{
    background-image: url('/public/image/front/cases/qr-code-on-product/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 912px;
        background-image: url('/public/image/front/cases/qr-code-on-product/bg-m.png');
    }
}
.bg-qr-sticker{
    background-image: url('/public/image/front/cases/qr-code-sticker-maker/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 1012px;
        background-image: url('/public/image/front/cases/qr-code-sticker-maker/bg-m.png');
    }
}
.bg-qr-poster{
    background-image: url('/public/image/front/cases/qr-poster/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        height: 950px;
        background-image: url('/public/image/front/cases/qr-poster/bg-m.webp');
    }
}
.bg-qr-hotels{
    background-image: url('/public/image/front/cases/qr-hotels/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 812px;
        background-image: url('/public/image/front/cases/qr-hotels/bg-m.png');
    }
}
.bg-qr-windows{
    background-image: url('/public/image/front/cases/qr-windows/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        height: 980px;
        background-image: url('/public/image/front/cases/qr-windows/bg-m.png');
    }
}
.bg-qr-games{
    background-image: url('/public/image/front/cases/qr-games/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 812px;
        background-image: url('/public/image/front/cases/qr-games/bg-m.png');
    }
}
.bg-qr-donation{
    background-image: url('/public/image/front/cases/qr-donation/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 812px;
        background-image: url('/public/image/front/cases/qr-donation/bg-m.png');
    }
}
.bg-qr-sign{
    background-image: url('/public/image/front/cases/qr-sign/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 1012px;
        background-image: url('/public/image/front/cases/qr-sign/bg-m.png');
    }
}
.bg-qr-gym{
    background-image: url('/public/image/front/cases/qr-gym/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 1012px;
        background-image: url('/public/image/front/cases/qr-gym/bg-m.png');
    }
}
.bg-stadium-qr{
    background-image: url('/public/image/front/cases/stadium-qr/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    @media screen and (max-width: 431px){
        min-height: 1012px;
        background-image: url('/public/image/front/cases/stadium-qr/bg-m.png');
    }
}
.bg-with-dots{
    background-image: url('/public/static/pages/qr-code-with-dots/main-img.webp');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
}
.bg-shapes{
    background-image: url('/public/static/pages/qr-codes-shapes/main-img.webp');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
}
.bg-formats{
    background-image: url('/public/static/pages/qr-code-formats/main-img.webp');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
}
.bg-article-banner{
    background-image: url("/public/static/pages/articles/article-banner-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 416px;
    @media screen and (max-width: 431px){
        min-height: 659px;
        background-image: url("/public/static/pages/articles/article-banner-bg.png");
    }
}
.lang-bar{
    height: 310px;
    &::-webkit-scrollbar {
        width: 8px;
        height: 10px;
    }
    &::-webkit-scrollbar-track{
        background: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #DDE0E4;
        border-radius: 20px;
    }
}
.lang-code{
    min-width: 25px;
}
.dropdown-item.lang {
    color: #8b929a;
    &:hover{
        background-color: #F5E7F8!important;
        border-radius: 4px;
    }

}
